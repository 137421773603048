import React, {useEffect, useState} from "react";

import {
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBRow,
  MDBModalFooter,
  MDBInput,
  MDBModal
} from 'mdbreact';

import { Button } from '@mui/material'
import GridItem from '../Grid/GridItem';
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import { RiMailCheckLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";

import Toolbar from "@material-ui/core/Toolbar";

import { useAuthDataContext } from "../../services/auth/AuthContext";

import "./NavbarDashboard.css"
import UserService from '../../services/user/User';

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function NavbarDashboard(props) {
  const userService = new UserService();
  const classes = useStyles();

  function makeBrand() {
    var name;
    props.data.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.title
      }
      return null
    })
    return name
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  })

  const {
    user_id,
    user,
    onLogout,
    getSession,
    emailCode,
    setEmailCode
  } = useAuthDataContext()
  
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    getSession()
  }, []);

  const handleCollapse = () => {
    setCollapse(!collapse)
  }

  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  const saveConfirmationCode = () => {
    console.log(emailCode)

    const data = {
      emailCode: emailCode,
      user_id: user_id,
      user: user

    }
    try{
      userService.updateEmailCode(data)
      .then(res => {
        if(res.status=200) {
          alert("Código atualizado com sucesso!!")
          toggle()
        }
        
      })
    }catch (erro) {
      console.log(erro)
    }
  }

  return (   
    <>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div className="title-page">
          {makeBrand()}
          </div>
        </div>
        <MDBNavbarToggler onClick={handleCollapse} />
      
        <MDBCollapse id="navbarCollapse3" isOpen={collapse} navbar >
          <MDBNavbarNav right className="options">
          <div class="navigation" style={{ paddingRight: "0"}}>
            <a class="button-user">    
              <span style={{ paddingRight: "10px" }}>Olá, {user.username}</span>
              <FiLogOut title="Sair" onClick={() => {onLogout()}}  className="ml-3"/>
              {user.is_superuser ? (<RiMailCheckLine title="Chave E-mail" className="ml-3" onClick={() => {toggle()}}/>):(null)}  

            </a>  
          </div>
          </MDBNavbarNav>
        </MDBCollapse>
      </Toolbar>

      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        disableBackdrop
      >
        <GridItem xs={12} sm={12} md={12}>
        <MDBInput
          validate
          required
          label="Código para E-mail *"
          hint={emailCode}
          type="text"
          value={emailCode}
          onChange={e => setEmailCode(e.target.value)}
        />
        </GridItem>
        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>
          <MDBModalFooter>
            {emailCode != "" ? (
              <Button disabled={emailCode != "" ? (false):(true)} variant="contained" onClick={saveConfirmationCode} >
                Atualizar Código
              </Button>
            ):(
              <Button disabled={emailCode != "" ? (false):(true)} variant="contained" onClick={saveConfirmationCode} >
                Salvar Código
              </Button>
            )}
          </MDBModalFooter>
        </MDBRow>
         
      </MDBModal>
</>
  )
}

NavbarDashboard.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
}
