import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBContainer,
	MDBCol,
	MDBIcon
} from 'mdbreact';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import { BiSolidAddToQueue } from "react-icons/bi";


import Table from './Table';
import Masks from "../Masks/Masks";
import ClientesService from '../../services/clientes/clientes';
import { useAuthDataContext } from '../../services/auth/AuthContext';
import { GetAllPrincipalComponent, GetLastPrincipalComponents } from "../GetAll";
import { MdDomainAdd } from "react-icons/md";
import { FiList } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import ModalFilterClientByField from './Modal/filterClientByField.js'

import ReactLoading from 'react-loading';

import './styles.css';

export default function CrudClientes() {
	// Carregamento tabela de Clientes
  const [isLoading, setIsLoading] = useState(false)
	const [isViewTable, setIsViewTable] = useState(false)
  const { user } = useAuthDataContext()
  const mask = new Masks()
  const clienteService = new ClientesService()

	// Modal cliente
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [clientes, setClientes] = useState([])
  const [cliente, setCliente] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  // Bloco de variáveis para clientes:
  const [id, setId] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [isCorp, setIsCorp] = useState(false)
  const [razaoSocial, setRazaoSocial] = useState('') 
  const [nomeFantasia, setNomeFantasia] = useState('')
  const [inscricaoEstadual, setInscricaoEstadual] = useState('')
  const [observacoes, setObservacoes] = useState('')
  const [notasPedido, setNotasPedido] = useState('')
  const [isAddressEqual, setIsAddressEqual] = useState(false)
	// Variáveis do modelo endereco Cadastral em cliente:
  const [idEnderecoCad, setIdEnderecoCad] = useState('')
  const [logradouroCad, setLogradouroCad] = useState('')
  const [numeroCad, setNumeroCad] = useState('')
  const [bairroCad, setBairroCad] = useState('')
  const [municipioCad, setMunicipioCad] = useState('')
  const [complementoCad, setComplementoCad] = useState('')
  const [cepCad, setCepCad] = useState('')
  const [ufCad, setUfCad] = useState('')
  // Variáveis do modelo endereco entrega em cliente:
  const [idEnderecoEntrega, setIdEnderecoEntrega] = useState('')
  const [logradouroEntrega, setLogradouroEntrega] = useState('')
  const [numeroEntrega, setNumeroEntrega] = useState('')
  const [bairroEntrega, setBairroEntrega] = useState('')
  const [municipioEntrega, setMunicipioEntrega] = useState('')
  const [complementoEntrega, setComplementoEntrega] = useState('')
  const [cepEntrega, setCepEntrega] = useState('')
  const [ufEntrega, setUfEntrega] = useState('')
  // Variáveis do modelo contato principal em cliente:
  const [idContatoPrin, setIdContatoPrinc] = useState('')
  const [departamentoPrinc, setDepartamentoPrinc] = useState('')
  const [emailPrinc, setEmailPrinc] = useState('')
  const [telefonePrinc, setTelefonePrinc] = useState('')
  const [celularPrinc, setCelularPrinc] = useState('')
  // Variáveis do modelo contato secundário em cliente:
  const [idContatoSec, setIdContatoSec] = useState('')
  const [departamentoSec, setDepartamentoSec] = useState('')
  const [emailSec, setEmailSec] = useState('')
  const [telefoneSec, setTelefoneSec] = useState('')
  const [celularSec, setCelularSec] = useState('')

  // Toggles
  const toggle = () => {
    setModal(!modal)
  }
  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Create
  const handleSubmit = () => {
    const cliente = {
      cnpj: cnpj,
      isCorp: isCorp,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      inscricaoEstadual: inscricaoEstadual,
      observacoes: observacoes, 
      notasPedido: notasPedido,
      
      logradouroCad: logradouroCad,
      numeroCad: numeroCad,
      bairroCad: bairroCad,
      municipioCad: municipioCad,
      complementoCad: complementoCad,
      cepCad: cepCad,
      ufCad: ufCad,

      logradouroEntrega: logradouroEntrega,
      numeroEntrega: numeroEntrega,
      bairroEntrega: bairroEntrega,
      municipioEntrega: municipioEntrega,
      complementoEntrega: complementoEntrega,
      cepEntrega: cepEntrega,
      ufEntrega: ufEntrega,

      departamentoPrinc: departamentoPrinc,
      emailPrinc: emailPrinc,
      telefonePrinc: telefonePrinc,
      celularPrinc: celularPrinc,

      departamentoSec: departamentoSec,
      emailSec: emailSec,
      telefoneSec: telefoneSec,
      celularSec: celularSec
    }

    try{
      clienteService.create(cliente)
      .then(res => {
        alert("Cliente criado com suceso!!")
        toggle()
        GetAllPrincipalComponent(setClientes, setError, clienteService, setIsLoading)
      })
    } catch (erro) {
      setError(erro)
    }
  }
  
  // Update
  const handleUpdate = (cliente) => {
    setIsUpdate(true)
		getCondsPgto(cliente.id)
 
    setId(cliente.id)
    setCnpj(cliente.cnpj)
    setIsCorp(cliente.is_corp)
    setRazaoSocial(cliente.razao_social)
    setNomeFantasia(cliente.nome_fantasia)
    setInscricaoEstadual(cliente.inscricao_estadual)
    setObservacoes(cliente.observacoes)
    setNotasPedido(cliente.notas_para_pedido)

    setIdEnderecoCad(cliente.endereco_cadastral.id)
    setLogradouroCad(cliente.endereco_cadastral.logradouro)
    setNumeroCad(cliente.endereco_cadastral.numero)
    setBairroCad(cliente.endereco_cadastral.bairro)
    setMunicipioCad(cliente.endereco_cadastral.municipio)
    setComplementoCad(cliente.endereco_cadastral.complemento)
    setCepCad(cliente.endereco_cadastral.cep)
    setUfCad(cliente.endereco_cadastral.uf)

    setIdEnderecoEntrega(cliente.endereco_entrega.id)
    setLogradouroEntrega(cliente.endereco_entrega.logradouro)
    setNumeroEntrega(cliente.endereco_entrega.numero)
    setBairroEntrega(cliente.endereco_entrega.bairro)
    setMunicipioEntrega(cliente.endereco_entrega.municipio)
    setComplementoEntrega(cliente.endereco_entrega.complemento)
    setCepEntrega(cliente.endereco_entrega.cep)
    setUfEntrega(cliente.endereco_entrega.uf)

    setIdContatoPrinc(cliente.contato_principal.id)
    setDepartamentoPrinc(cliente.contato_principal.departamento)
    setEmailPrinc(cliente.contato_principal.email)
    setTelefonePrinc(cliente.contato_principal.telefone)
    setCelularPrinc(cliente.contato_principal.celular)

    if (cliente.contato_secundario !== null) {
      setIdContatoSec(cliente.contato_secundario.id)
      setDepartamentoSec(cliente.contato_secundario.departamento)
      setEmailSec(cliente.contato_secundario.email)
      setTelefoneSec(cliente.contato_secundario.telefone)
      setCelularSec(cliente.contato_secundario.celular)}

    toggle()
  }

  // Verificando o update
  const confirmUpdate = () => {
    const cliente = {
      id: id,
      cnpj: cnpj,
      isCorp: isCorp,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      inscricaoEstadual: inscricaoEstadual,
      observacoes: observacoes,
      notasPedido: notasPedido,
      
      idEnderecoCad: idEnderecoCad,
      logradouroCad: logradouroCad,
      numeroCad: numeroCad,
      bairroCad: bairroCad,
      municipioCad: municipioCad,
      complementoCad: complementoCad,
      cepCad: cepCad,
      ufCad: ufCad,

      idEnderecoEntrega: idEnderecoEntrega,
      logradouroEntrega: logradouroEntrega,
      numeroEntrega: numeroEntrega,
      bairroEntrega: bairroEntrega,
      municipioEntrega: municipioEntrega,
      complementoEntrega: complementoEntrega,
      cepEntrega: cepEntrega,
      ufEntrega: ufEntrega,

      idContatoPrin: idContatoPrin,
      departamentoPrinc: departamentoPrinc,
      emailPrinc: emailPrinc,
      telefonePrinc: telefonePrinc,
      celularPrinc: celularPrinc,

      idContatoSec: idContatoSec,
      departamentoSec: departamentoSec,
      emailSec: emailSec,
      telefoneSec: telefoneSec,
      celularSec: celularSec
    }

    try {
      clienteService.update(cliente.id, cliente)
      .then(res => {
        if (res.status === 200) {
          alert("Cliente atualizado com sucesso!!") 
          toggle()
          GetAllPrincipalComponent(setClientes, setError, clienteService, setIsLoading)
        }
      })
    } catch(error) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete
  const handleDelete = (idCliente) => {
    clienteService.delete(idCliente)
    .then(res =>{
      if (res.status === 200) {
        GetAllPrincipalComponent(setClientes, setError, clienteService, setIsLoading)
        toggleDelete()
        alert("Cliente apagado com sucesso!!")
        }
    })
  }

  const checkValuesCliente = () => {
 
    if (
      razaoSocial=="" || 
      cnpj=="" ||
      inscricaoEstadual=="" ||
      logradouroCad=="" ||
      numeroCad==""  ||
      bairroCad=="" ||
      municipioCad=="" ||
      cepCad=="" ||
      ufCad=="" ||
      logradouroEntrega=="" ||
      numeroEntrega==""  ||
      bairroEntrega=="" ||
      municipioEntrega=="" ||
      cepEntrega=="" ||
      ufEntrega=="" ||
      emailPrinc=="" ||
      departamentoPrinc==""
      
      



    ) {
      return true
    } else {
      if (
     
        cnpj.length < 18 ||
        inscricaoEstadual.length < 15 ||
        cepCad.length < 10 ||
        ufCad.length != 2 ||
        cepEntrega.length < 10 ||
        ufEntrega.length != 2 
      ) {
        return true
      } else {
        return false
      }
    }
  } 

  const handleCheckboxChange = (checked) => {
    setIsAddressEqual(checked);
  
    if (checked) {
      // Quando o checkbox está marcado
      setIdEnderecoEntrega(idEnderecoCad);
      setLogradouroEntrega(logradouroCad);
      setNumeroEntrega(numeroCad);
      setBairroEntrega(bairroCad);
      setMunicipioEntrega(municipioCad);
      setComplementoEntrega(complementoCad);
      setCepEntrega(cepCad);
      setUfEntrega(ufCad);
    } else {
      // Quando o checkbox está desmarcado
      setIdEnderecoEntrega("");
      setLogradouroEntrega("");
      setNumeroEntrega("");
      setBairroEntrega("");
      setMunicipioEntrega("");
      setComplementoEntrega("");
      setCepEntrega("");
      setUfEntrega("");
    }
  };

  const clearState = () => {

    setCnpj("")
    setIsCorp(false)
    setRazaoSocial("")
    setNomeFantasia("")
    setInscricaoEstadual("")
    setObservacoes("")
    setNotasPedido("")

    setLogradouroCad("")
    setNumeroCad("")
    setBairroCad("")
    setMunicipioCad("")
    setComplementoCad("")
    setCepCad("")
    setUfCad("")

    setIdEnderecoEntrega("")
    setLogradouroEntrega("")
    setNumeroEntrega("")
    setBairroEntrega("")
    setMunicipioEntrega("")
    setComplementoEntrega("")
    setCepEntrega("")
    setUfEntrega("")
    
    setDepartamentoPrinc("")
    setEmailPrinc("")
    setTelefonePrinc("")
    setCelularPrinc("")

    setDepartamentoSec("")
    setEmailSec("")
    setTelefoneSec("")
    setCelularSec("")

		setCondsPgto([])
		setCondPgto("")
  }

  useEffect(() => {
    GetAllPrincipalComponent(setClientes, setError, clienteService, setIsLoading)
  }, [])


	// Condição do Pagamento
	const [modalCondPgto, setModalCondPgto] = useState(false)
	const [modalDeleteCondPgto, setModalDeleteCondPgto] = useState(false)
	const [condsPgto, setCondsPgto] = useState([])
	const [condPgto, setCondPgto] = useState(null)
  const [isUpdateCondPgto, setIsUpdateCondPgto] = useState(false)
	// Bloco de variáveis
	const [idCondPgto, setIdCondPgto] = useState('')
	const [condicao, setCondicao] = useState('')
	const [prazo, setPrazo] = useState('')
	const [descontoComercial, setDescontoComercial] = useState('')
  const [descricaoCondicao, setDescricaoCondicao] = useState('')
	const [showInnerModal, setShowInnerModal] = useState(false);
	// Modal Cond PGTO
	
	const toggleCondPgto = () => {
		setModalCondPgto(!modalCondPgto)
	}
	const toggleDeleteCondPgto = () => {
		setModalDeleteCondPgto(!modalDeleteCondPgto)
	}
  // UPD CondPgto
  const handleUpdateCondPgto = (condPgtoModel) => {
    console.log(condPgtoModel)
    setIdCondPgto(condPgtoModel.id)
    setCondicao(condPgtoModel.condicao)
    setPrazo(condPgtoModel.prazo)
    setDescontoComercial(condPgtoModel.desconto_comercial)
    setDescricaoCondicao(condPgtoModel.descricao)
  }

  const confirmUpdateCondPgto = () => {
    const condPgto = {
      idCondPgto: idCondPgto,
      condicao: condicao,
			prazo: prazo,
			descontoComercial: descontoComercial,
      descricaoCondicao: descricaoCondicao
    }

    try {
      clienteService.updateCondPgto(idCondPgto, condPgto)
      .then(res => {
        if (res.status === 200) {
          alert("Condição atualizada com sucesso!!")
          toggleCondPgto()
					getCondsPgto(id)
					toggle()
          //GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }
	// Create CondPgto
  const handleSubmitCondPgto = () => {
    console.log(condicao)
    const condPgto = {
      condicao: condicao,
			prazo: prazo,
			descontoComercial: descontoComercial,
      descricaoCondicao: descricaoCondicao
    }
    try {
      clienteService.createCondPgto(id, condPgto)
      .then(res => {
        if (res.status === 200) {
          alert("Condição criada com sucesso!!")
          toggleCondPgto()
					getCondsPgto(id)
					toggle()
          //GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }    

	// Delete CondPgto
  const handleDeleteCondPgto = (idCondPgto) => {
    clienteService.deleteCondPgto(idCondPgto)
    .then(res => {
      console.log(res.status);
      if (res.status === 200) {
        // Operações de sucesso
        toggleDeleteCondPgto();
        getCondsPgto(id);
        alert('Condição apagada com sucesso!');
        toggle();
      }
    })
    .catch(err => {
      getCondsPgto(id);
      alert('Erro ao apagar a condição. Ela está relacionada ao pedido!');
      toggleDeleteCondPgto();
      toggle();
    });
  }

	const getCondsPgto = (idCliente) => {
    clienteService.getCondPgtoByCliente(idCliente)
    .then(response => {
      if (response.status === 200) {
        setCondsPgto(response.data) 
      } else {
        setError('Erro aos listar condições de Pagamento!!')
      }
    })
    .catch(error => {
      setError(error)
    }) 
  }

	const getCondicao = (condicao) => {
		if (condicao === 1) {
			return "Padrão"
		} else if (condicao === 2) {
			return "Especial"        
		}
	}

  const checkValuesCondPgto = () => {

    if (
			condicao=="" ||
			prazo=="" ||
			descontoComercial==""
    ) {
      return true
    } else {
      return false
    }
  } 

  const clearStateCondPgto = () => {
    setCondicao("")
		setPrazo("")
		setDescontoComercial("")
  }

  return(
    <div>
      {/* Container de inserção de informação (Create/Update/Read Individual)*/}
      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        size="lg"
        disableBackdrop
      >
        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre o cliente</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre novo cliente</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody className="modal-body">
          <form  className="form" >
            <div className="info-form">
                <div>
                  <GridItem 
                  className="title-subinfo"
                  style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}>
                    Informações Gerais
                  </GridItem>
                </div>
                <GridContainer >
                <GridItem xs={6} sm={12} md={4}>
                  <label style={{marginRight: '5px'}}>
                    Cliente Corporativo?
                  </label>

                  <input type="checkbox"
                    checked={isCorp}
                    onChange={() => setIsCorp(!isCorp)}
                  />
                </GridItem>
              </GridContainer>
              

              <GridContainer >
                <GridItem xs={6} sm={12} md={3}>
                  <MDBInput
                    validate
                    required
                    label="Razão Social *"
                    hint={razaoSocial}
                    type="text"
                    value={razaoSocial}
                    onChange={e => setRazaoSocial(mask.charAndText(e.target.value))}
                  />
                </GridItem>

                <GridItem className="grid-item" xs={12} sm={12} md={3}>
                  <MDBInput
                    validate
                    required
                    label="CNPJ *"
                    hint={cnpj}
                    type="text"
                    value={cnpj}
                    onChange={e => setCnpj(mask.cnpj(e.target.value))}
                  />
                </GridItem>
              
                <GridItem xs={6} sm={12} md={3}>
                  <MDBInput
                    label="Nome Fantasia"
                    hint={nomeFantasia}
                    type="text"
                    value={nomeFantasia}
                    onChange={e => setNomeFantasia(mask.charAndText(e.target.value))}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <MDBInput
                    validate
                    required
                    label="Inscrição Estadual *"
                    hint={inscricaoEstadual}
                    type="text"
                    value={inscricaoEstadual}
                    onChange={e => setInscricaoEstadual(mask.ie(e.target.value))}
                  />
                </GridItem>
              </GridContainer>

							<GridContainer>
								<GridItem className="grid-item" xs={12} sm={12} md={12}>
									<MDBInput
										validate
										required
										label="Observações"
										hint={observacoes}
										type="textarea"
										value={observacoes}
										onChange={e => setObservacoes(mask.charAndText(e.target.value))}
									/>
								</GridItem>
							</GridContainer>
              <GridContainer>
								<GridItem className="grid-item" xs={12} sm={12} md={12}>
									<MDBInput
                    helperText="Esse campo será mostrado no PDF do Pedido"
										validate
										required
										label="Notas para pedido"
										hint={notasPedido}
										type="textarea"
										value={notasPedido}
										onChange={e => setNotasPedido(mask.charAndText(e.target.value))}
									/>
								</GridItem>
							</GridContainer>
                  
              <div>
                <GridItem 
                className="title-subinfo"
                style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#ADC5B4', 
                  color: 'white', 
                  fontSize: '22px', 
                  marginLeft: '0', 
                  marginRight: '0', 
                  fontWeight: '600', 
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                  Condições de Pagamento


                {isUpdate ? ( 
                  user.is_superuser ? 
                  (
                    <BiSolidAddToQueue 
                      title='Adicionar Condição'
                      className="btn-manage"
                      onClick={() => {
                        clearStateCondPgto()
                        toggle()
                        toggleCondPgto() 
                      }}
                      style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
                    />                
                  ):(
                    <BiSolidAddToQueue 
                      title='Adicionar Condição'
                        className="btn-manage"
                        onClick={() => {
                          clearStateCondPgto()
                          toggle()
                          toggleCondPgto() 
                          setCondicao("2")
                        }}
                        style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
                    />
                  )
								
							  ):(null)}
                  
                </GridItem>
              </div>
							<GridContainer>
                <GridItem xs={12} sm={12} md={12}>
									{condsPgto.length > 0 ? (
										<>
											<MDBContainer>
												<MDBRow>
                          <MDBCol md={2} className="d-flex justify-content-start">
														<span style={{ fontSize: "12px", fontWeight: "bold" }}>PRAZO</span>
													</MDBCol>
                          <MDBCol md={2} className="d-flex justify-content-start">
														<span style={{ fontSize: "12px", fontWeight: "bold" }}>DESCONTO (%)</span>
													</MDBCol>
                          <MDBCol md={2} className="d-flex justify-content-start" >
														<span style={{ fontSize: "12px", fontWeight: "bold" }}>CONDIÇÃO</span>
													</MDBCol>
                          <MDBCol md={4} className="d-flex justify-content-start">
														<span style={{ fontSize: "12px", fontWeight: "bold" }}>DESCRIÇÃO</span>
													</MDBCol>  
													<MDBCol md={2} className="d-flex justify-content-start">
														<span style={{ fontSize: "12px", fontWeight: "bold" }}>AÇÕES</span>
													</MDBCol>
												</MDBRow>
											</MDBContainer>
											<MDBContainer>
												{condsPgto.map((cond, idx) => (
													<MDBRow style={{ marginTop: '15px' }}>
                            <MDBCol md={2} className="d-flex justify-content-start">
															<span style={{ fontSize: "12px", fontWeight: "bold" }}>{cond.prazo}</span>
														</MDBCol>
														<MDBCol md={2} className="d-flex justify-content-start">
															<span style={{ fontSize: "12px", fontWeight: "bold" }}>{cond.desconto_comercial}</span>
														</MDBCol>
														<MDBCol md={2} className="d-flex justify-content-start" >
															<span style={{ fontSize: "12px", fontWeight: "bold" }}>{getCondicao(cond.condicao)}</span>
														</MDBCol>
                            <MDBCol md={4} className="d-flex justify-content-start" >
															<span style={{ fontSize: "12px", fontWeight: "bold" }}>{cond.descricao}</span>
														</MDBCol>
															<MDBCol md={2}>    
                                <CiEdit  
                                onClick={() => {
                                  setCondPgto(cond)
                                  setIdCondPgto(cond.id)
                                  toggle()
                                  toggleCondPgto()   
                                  handleUpdateCondPgto(cond)
                                  setIsUpdateCondPgto(true)
                                  }} 
                                  style={{color: '#B19B80', height: '30px', width: '30px', marginRight: '15px', cursor: 'pointer'}}
                                />
                                <FaTrashAlt  
                                  onClick={() => {
                                  toggle()
                                  setCondPgto(cond)
                                  setIdCondPgto(cond.id)
                                  toggleDeleteCondPgto()   
                                  }} 
                                  style={{color: '#B19B80', height: '20px', width: '20px', cursor: 'pointer'}}
                                />
															</MDBCol>
													</MDBRow>
												))}
											</MDBContainer>
										</>
									):(null)}
                </GridItem>
							</GridContainer>
              
              <div>
                <GridItem 
                  className="title-subinfo"
                  style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}>
                    Endereço Cadastral
                  </GridItem>
                </div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MDBInput
                      validate
                      required                
                      label="Logradouro *"
                      hint={logradouroCad}
                      type="text"
                      value={logradouroCad}
                      onChange={e => setLogradouroCad(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <MDBInput
                      validate
                      required
                      label="Número *"
                      hint={numeroCad}
                      type="text"
                      value={numeroCad}
                      onChange={e => setNumeroCad(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <MDBInput
                      validate
                      required
                      label="Bairro *"
                      hint={bairroCad}
                      type="text"
                      value={bairroCad}
                      onChange={e => setBairroCad(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MDBInput
                      validate
                      required
                      label="Município *"
                      hint={municipioCad}
                      type="text"
                      value={municipioCad}
                      onChange={e => setMunicipioCad(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <MDBInput
                      validate
                      required
                      label="CEP *"
                      hint={cepCad}
                      type="text"
                      name="cep"
                      value={cepCad}
                      onChange={e => setCepCad(mask.cep(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                      <MDBInput
                        validate
                        required
                        label="UF *"
                        hint={ufCad}
                        type="text"
                        name="uf"
                        value={ufCad}
                        onChange={e => setUfCad(mask.uf(e.target.value))}
                      />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <MDBInput
                      label="Complemento"
                      hint={complementoCad}
                      type="textarea"
                      value={complementoCad}
                      onChange={e => setComplementoCad(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                </GridContainer>
            
                <div>
                  <GridItem 
                  className="title-subinfo"
                  style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}>
                    Endereço Entrega  
                    </GridItem>                
                </div>
             
                    {isUpdate ? (null):(
                      <>
                      <GridItem xs={6} sm={12} md={12}>
                      <label style={{marginRight: '5px'}}>
                        Repetir Endereço Cadastral?
                      </label>

                      <input type="checkbox"
                        checked={isAddressEqual}
                        onChange={(e) => {
                          handleCheckboxChange(e.target.checked);
                        }}
                      />
                    </GridItem>
                  </>
                  )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MDBInput
                      validate
                      required
                      label="Logradouro *"
                      hint={logradouroEntrega}
                      type="text"
                      value={logradouroEntrega}
                      onChange={e => setLogradouroEntrega(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <MDBInput
                      validate
                      required
                      label="Número *"
                      hint={numeroEntrega}
                      type="text"
                      value={numeroEntrega}
                      onChange={e => setNumeroEntrega(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <MDBInput
                      validate
                      required
                      label="Bairro *"
                      hint={bairroEntrega}
                      type="text"
                      value={bairroEntrega}
                      onChange={e => setBairroEntrega(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MDBInput
                      validate
                      required
                      label="Município *"
                      hint={municipioEntrega}
                      type="text"
                      value={municipioEntrega}
                      onChange={e => setMunicipioEntrega(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <MDBInput
                      validate
                      required
                      label="CEP *"
                      hint={cepEntrega}
                      type="text"
                      name="cep"
                      value={cepEntrega}
                      onChange={e => setCepEntrega(mask.cep(e.target.value))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                      <MDBInput
                        validate
                        required
                        label="UF *"
                        hint={ufEntrega}
                        type="text"
                        name="uf"
                        value={ufEntrega}
                        onChange={e => setUfEntrega(mask.uf(e.target.value))}
                      />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <MDBInput
                      label="Complemento"
                      hint={complementoEntrega}
                      type="textarea"
                      value={complementoEntrega}
                      onChange={e => setComplementoEntrega(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                </GridContainer>
              
                <div>
                <GridItem 
                  className="title-subinfo"
                  style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}>
                    Contato Principal
                  </GridItem>
                </div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <MDBInput
                      validate
                      required
                      label="E-mail *"
                      hint={emailPrinc}
                      type="text"
                      value={emailPrinc}
                      onChange={e => setEmailPrinc(mask.email(e.target.value))}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <MDBInput
                      label="Telefone "
                      hint={telefonePrinc}
                      type="text"
                      name="telefone"
                      value={telefonePrinc}
                      onChange={e => setTelefonePrinc(mask.telefone(e.target.value))}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <MDBInput
                      label="Celular"
                      hint={celularPrinc}
                      type="text"
                      name="celular"
                      value={celularPrinc}
                      onChange={e => setCelularPrinc(mask.celular(e.target.value))}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <MDBInput
                      validate
                      required
                      label="Departamento *"
                      hint={departamentoPrinc}
                      type="text"
                      value={departamentoPrinc}
                      onChange={e => setDepartamentoPrinc(mask.charAndText(e.target.value))}
                    />
                  </GridItem>
                </GridContainer>
              <div>
                  <GridItem 
                  className="title-subinfo"
                  style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}>
                    Contato Secundário
                  </GridItem>
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                      <MDBInput
                        validate
                        required
                        label="E-mail"
                        hint={emailSec}
                        type="text"
                        value={emailSec}
                        onChange={e => setEmailSec(mask.email(e.target.value))}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <MDBInput
                        validate
                        required
                        label="Telefone"
                        hint={telefoneSec}
                        type="text"
                        name="telefone"
                        value={telefoneSec}
                        onChange={e => setTelefoneSec(mask.telefone(e.target.value))}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <MDBInput
                        label="Celular"
                        hint={celularSec}
                        type="text"
                        name="celular"
                        value={celularSec}
                        onChange={e => setCelularSec(mask.celular(e.target.value))}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <MDBInput
                        label="Departamento"
                        hint={departamentoSec}
                        type="text"
                        value={departamentoSec}
                        onChange={e => setDepartamentoSec(mask.charAndText(e.target.value))}
                      />
                    </GridItem>
                  </GridContainer>
                </div>              
          </form>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesCliente()} variant="contained" onClick={confirmUpdate}>
                Atualizar Cliente
              </Button>
            ): (        
              <Button disabled={checkValuesCliente()} variant="contained" onClick={handleSubmit}>
                Registrar Cliente
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>

      {/* Modal de veificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse cliente?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    CNPJ do Cliente: {cliente.cnpj}
                  </span>
              </MDBRow>

              <MDBRow className="mt-4" center>
                  <span>
                    Razão Social do Cliente: {cliente.razao_social}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter >
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(cliente.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}
     
			{/* Cond PGTO */}

			{/* Modal de criar cond pgto */}
      {modalCondPgto ? (
        <MDBModal 
          isOpen={modalCondPgto}
          toggle={toggleCondPgto}
          size="lg"
        	disableBackdrop
        >
					<MDBRow center>
							<MDBModalHeader className="modal-header">Informações sobre o Condições de Pagamento</MDBModalHeader>
					</MDBRow>
          <MDBModalBody>
            <div className="info-form">
							<GridContainer>
							<GridItem 
								className="grid-item" 
								xs={12} 
								sm={12} 
								md={6} 
							>
                {user.is_superuser ? (
                  <>
                    <InputLabel>
                      Condição de Pagamento*
                    </InputLabel>
                    <NativeSelect
                      value={condicao}
                      onChange={e => 
                        setCondicao(e.target.value)
                      }
                      >                   
                      <option>Selecione o tipo de Condição</option>
                      <option value="1">Padrão</option>
                      <option value="2">Especial</option>
                  </NativeSelect>
                </>
                ):(
                  <>
                    <InputLabel>
                      Condição de Pagamento*
                    </InputLabel>
                    
                    <NativeSelect
                      disabled={true}
                      value={condicao}
                      onChange={e => 
                          setCondicao(e.target.value)
                      }
                      >                   
                      <option value="2">Especial</option>
                    </NativeSelect>
                  </>
                )} 
								</GridItem>

								<GridItem 
									id="descCom" 
									className="grid-item" 
									xs={12} 
									sm={12} 
									md={6} 
								>
									<MDBInput 
										label="Desconto Comercial (%) *" 
										value={descontoComercial}
										disabled={false}
										onChange={(e) => setDescontoComercial(mask.formatDescontoAndComissao(e.target.value))}
									/>
								</GridItem>
								<GridItem 
									id="prazoPgto" 
									className="grid-item" 
									xs={12} 
									sm={12} 
									md={6} 
								>
									<MDBInput 
										label="Prazo Pagamento (ex. 30DD)" 
										value={prazo}
										onChange={(e) => setPrazo(mask.charAndText(e.target.value))}
									/>
								</GridItem>

                <GridItem className="grid-item" xs={12} sm={12} md={6}>
                  <MDBInput 
                    label="Descrição" 
                    type="textarea" validate 
                    value={descricaoCondicao}
                    onChange={input => setDescricaoCondicao(mask.charAndText(input.target.value))}
                  />
                </GridItem>
							</GridContainer>
            </div>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
                toggleCondPgto() 
                toggle()}}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>    
              {isUpdateCondPgto ? (
                <Button disabled={checkValuesCondPgto()} variant="contained" onClick={confirmUpdateCondPgto}>
                Atualizar Condição
                </Button>
                ):(
                  <Button disabled={checkValuesCondPgto()} variant="contained" onClick={handleSubmitCondPgto}>
                  Registrar Condição
                </Button>
              )} 
               
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>
      ) : (null)} 


			 {/* Modal de verificação do delete do pedido*/}
			 {modalDeleteCondPgto ? (
        <MDBModal
          isOpen={modalDeleteCondPgto}
          toggle={toggleDeleteCondPgto}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir essa Condição de Pagamento?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Condição: {getCondicao(condPgto.condicao)}
                  </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Desconto {condPgto.desconto_comercial}
                  </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Prazo: {condPgto.prazo}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDeleteCondPgto}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDeleteCondPgto(condPgto.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

      <CardHeader 
        className="header-table" 
        style={{ backgroundColor: '#B19B80', color: 'white' }}>
        <h4 className="title-table">Ações de Clientes</h4>
        <div
          style={{alignContent: 'end', display: 'flex', flexDirection: 'row'}}
        >
          { <ModalFilterClientByField 
            clienteService={clienteService} 
            setClientes={setClientes}
            setIsLoading={setIsLoading}
            setIsViewTable={setIsViewTable}
          /> }
          <FiList 
            title="Carregar Clientes"
            onClick={() => {
              GetLastPrincipalComponents(setClientes, setError, clienteService, setIsLoading, setIsViewTable)
            }}
            style={{ height: '24px', color: '#F6EDD2', width: '24px', marginLeft: '10px' }}
          />
          <MdDomainAdd 
            title="Adicionar Cliente"
            onClick={() => {
              setIsUpdate(false)
              clearState()
              toggle()
            }}
            style={{ height: '24px', color: '#F6EDD2', width: '24px', marginLeft: '10px' }}
          />
        </div>
      </CardHeader>


       {/* Table Inicial */}
       {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ):(null)}
      {isViewTable ? (
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="header-table" style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
              <h4 className="title-table">Lista de Clientes</h4>
              <p className="p-table">
                  Adicione e edite os clientes.
              </p>
            </CardHeader>
            <CardBody>
              <Table
              modelos={clientes}
              handleUpdate={handleUpdate}
              toggleDelete={toggleDelete}
              setModelo={setCliente}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      ):(null)}
    </div>
      
      
  )
}